import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import $ from "jquery";
import Select from "react-select";
import update from "immutability-helper";
import { validated } from "react-custom-validation";
import validator from "validator";
import { format } from "date-fns";
import { GET_FORMPOST, GET_DETAILDATA } from "../../../actions";
import { apiUrl, masterheaderconfig } from "../../Helpers/Config";
import {
  showLoader,
  hideLoader,
  showAlert,
  userID,
  isSingleSelect,
  decodeValue,
  passwordChar,
  uploadFile,
  removeFile,
} from "../../Helpers/SettingHelper";
import PageLoader from "../../Helpers/PageLoader";
import Header from "../Layout/Header";
import Topmenu from "../Layout/Topmenu";
import Footer from "../Layout/Footer";
import Switch from "react-switch";

var todayDate = new Date();
class Clientform extends Component {
  constructor(props) {
    super(props);
    var editID = "";
    if (this.props.match.path === "/masterpanel/client/edit/:clientID") {
      editID = this.props.match.params.clientID;
    }
    this.state = {
      editID: editID,
      pageloading: false,
      clientdata: {
        company_name: "",
        company_username: "",
        company_password: "",
        company_cpassword: "",
        company_email_address: "",
        category_list: [],
        company_country: [],
        company_currency: [],
        company_language: [],
        company_amount: "",
        company_date_format: [],
        company_time_format: [],
        company_logo: "",
        company_status: "",
        company_merchants_type: { label: "Complete", value: "1" },
        enable_subscription: false,
        enable_maintenance_mode: false,
        enable_loyalty: false,
        enable_promocode: false,
        enable_new_signup_promocode: false,
        enable_holiday: false,
        enable_order_count: false,
        enable_tax: false,
        enable_highlight_product: false,
        enable_product_time_availability: false,
        enable_productspecial_days: false,
        enable_product_rating: false,
        enable_stock: false,
        enable_stock_auto_update: false,
        sms_count: "",
        enable_corporate_customer: false,
        enable_membership: false,
        enable_strip: false,
        enable_manual_payment: false,
        company_payment_method: [],
        enable_mswipe_payment: false,
        mswipe_mode: false,
        mswipe_refid: "",
        mswipe_custCode: "",
        mswipe_user_name: "",
        mswipe_user_pwd: "",
        enable_reddot_payment: false,
        reddot_mode_mv3ds: false,
        reddot_mid_mv3ds: "",
        reddot_secretKey_mv3ds: "",
        reddot_mode_amex3ds: false,
        reddot_mid_amex3ds: "",
        reddot_secretKey_amex3ds: "",
        reddot_mode_mvwithout3ds: false,
        reddot_mid_mvwithout3ds: "",
        reddot_secretKey_mvwithout3ds: "",
        reddot_mode_amexwithout3ds: false,
        reddot_mid_amexwithout3ds: "",
        reddot_secretKey_amexwithout3ds: "",
        reddot_mode_applepay: false,
        reddot_mid_applepay: "",
        reddot_secretKey_applepay: "",
        reddot_mode_googlepay: false,
        reddot_mid_googlepay: "",
        reddot_secretKey_googlepay: "",
        reddot_mode_atome: false,
        reddot_mid_atome: "",
        reddot_secretKey_atome: "",
        reddot_mode_paynow: false,
        reddot_mid_paynow: "",
        reddot_secretKey_paynow: "",
        reddot_mode_grabpay: false,
        reddot_mid_grabpay: "",
        reddot_secretKey_grabpay: "",
        action: "add",
      },
      btndisabled: false,
      loading: true,
      checked: true,
      categoryList: [],
      countryList: [],
      currencyList: [],
      languageList: [],
      formpost: [],
      companyDetail: [],
    };
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount() {
    this.companycategoryList();
    this.loadCountyList();
    this.loadCurrency();
    this.loadLanguage();

    if (this.state.editID !== "") {
      var params = {
        params: "company_id=" + this.state.editID,
        url: apiUrl + "company/companycontroller/company_details",
        type: "master",
      };
      this.setState({ pageloading: true });
      this.props.getDetailData(params);
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.state.formpost !== nextProps.formpost &&
      this.props.formpost !== nextProps.formpost
    ) {
      if (nextProps.formpost.length > 0) {
        hideLoader("submit_frm", "class");
        var errMsg =
          nextProps.formpost[0].form_error !== ""
            ? nextProps.formpost[0].form_error
            : nextProps.formpost[0].message;
        if (nextProps.formpost[0].status === "success") {
          showAlert("Success", errMsg, "success");
          this.props.history.push("/masterpanel/client");
        } else {
          showAlert("Error", errMsg, "error");
        }
      }
    }
    if (
      this.state.companyDetail !== nextProps.detaildata &&
      this.state.editID !== ""
    ) {
      this.setState({ companyDetail: nextProps.detaildata }, function () {
        if (nextProps.detaildata[0].status === "ok") {
          var result = nextProps.detaildata[0].result;
          var company_status_txt = "Inactive";
          if (result.company_status === "A") {
            company_status_txt = "Active";
          } else if (result.company_status === "I") {
            company_status_txt = "Inactive";
          } else if (result.company_status === "D") {
            company_status_txt = "Deleted";
          }
          var status =
            result.company_status !== "" && result.company_status !== null
              ? {
                  label: company_status_txt,
                  value: result.company_status,
                }
              : "";
          var companymerchantstype = { label: "Complete", value: "1" };
          if (result.company_merchants_type === "1") {
            companymerchantstype = { label: "Complete", value: "1" };
          } else if (result.company_merchants_type === "2") {
            companymerchantstype = { label: "Voucher Only", value: "2" };
          }
          var clientupdatedata = {
            company_name: result.company_name,
            company_username: result.company_username,
            company_password: "",
            company_cpassword: "",
            company_email_address: result.company_email_address,
            category_list: result.company_categories_arr,
            company_country: {
              label: result.country,
              value: result.company_country,
            },

            company_currency: {
              label: result.company_currency,
              value: result.company_currency,
            },
            company_language: {
              label: result.language,
              value: result.company_language,
            },
            company_amount: result.company_amount,
            company_status: status,
            company_merchants_type: companymerchantstype,
            company_logo:
              result.company_logo !== "" && result.company_logo !== null
                ? result.company_logo
                : "",
            company_date_format:
              result.company_date_format !== "" &&
              result.company_date_format !== null
                ? {
                    label: format(todayDate, result.company_date_format),
                    value: result.company_date_format,
                  }
                : "",
            company_time_format:
              result.company_date_format !== "" &&
              result.company_date_format !== null
                ? {
                    label: format(todayDate, result.company_time_format),
                    value: result.company_time_format,
                  }
                : "",
            enable_subscription: result?.enable_subscription || false,
            enable_maintenance_mode: result?.enable_maintenance_mode || false,
            enable_loyalty: result?.enable_loyalty || false,
            enable_promocode: result?.enable_promocode || false,
            enable_new_signup_promocode:
              result?.enable_new_signup_promocode || false,
            enable_holiday: result?.enable_holiday || false,
            enable_order_count: result?.enable_order_count || false,
            enable_tax: result?.enable_tax || false,
            enable_highlight_product: result?.enable_highlight_product || false,
            enable_product_time_availability:
              result?.enable_product_time_availability || false,
            enable_productspecial_days:
              result?.enable_productspecial_days || false,
            enable_product_rating: result?.enable_product_rating || false,
            enable_stock: result?.enable_stock || false,
            enable_stock_auto_update: result?.enable_stock_auto_update || false,
            sms_count: result?.company_sms_balance || "",
            enable_corporate_customer:
              result?.enable_corporate_customer || false,
            enable_membership: result?.enable_membership || false,
            enable_strip: result?.enable_strip === "1" ? true : false,
            enable_manual_payment:
              result?.enable_manual_payment === "true"
                ? Boolean(result?.enable_manual_payment)
                : false,
            enable_mswipe_payment:
              Boolean(result?.enable_mswipe_payment) || false,
            mswipe_mode: result?.mswipe_mode === "1" ? true : false,
            mswipe_refid: result?.mswipe_refid || "",
            mswipe_custCode: result?.mswipe_custCode || "",
            mswipe_user_name: result?.mswipe_user_name || "",
            mswipe_user_pwd: result?.mswipe_user_pwd
              ? decodeValue(result?.mswipe_user_pwd)
              : "",
            enable_reddot_payment:
              Boolean(result?.enable_reddot_payment) || false,
            reddot_mode_mv3ds: result?.reddot_mode_mv3ds === 1 ? true : false,

            reddot_mid_mv3ds: result?.reddot_mid_mv3ds || "",
            reddot_secretKey_mv3ds: result?.reddot_secretKey_mv3ds
              ? decodeValue(result?.reddot_secretKey_mv3ds)
              : "",
            reddot_mode_amex3ds:
              result?.reddot_mode_amex3ds === 1 ? true : false,
            reddot_mid_amex3ds: result?.reddot_mid_amex3ds || "",
            reddot_secretKey_amex3ds: result?.reddot_secretKey_amex3ds
              ? decodeValue(result?.reddot_secretKey_amex3ds)
              : "",
            reddot_mode_mvwithout3ds:
              result?.reddot_mode_mvwithout3ds === 1 ? true : false,
            reddot_mid_mvwithout3ds: result?.reddot_mid_mvwithout3ds || "",
            reddot_secretKey_mvwithout3ds: result?.reddot_secretKey_mvwithout3ds
              ? decodeValue(result?.reddot_secretKey_mvwithout3ds)
              : "",
            reddot_mode_amexwithout3ds:
              result?.reddot_mode_amexwithout3ds === 1 ? true : false,
            reddot_mid_amexwithout3ds: result?.reddot_mid_amexwithout3ds || "",
            reddot_secretKey_amexwithout3ds:
              result?.reddot_secretKey_amexwithout3ds
                ? decodeValue(result?.reddot_secretKey_amexwithout3ds)
                : "",
            reddot_mode_applepay:
              result?.reddot_mode_applepay === 1 ? true : false,
            reddot_mid_applepay: result?.reddot_mid_applepay || "",
            reddot_secretKey_applepay: result?.reddot_secretKey_applepay
              ? decodeValue(result?.reddot_secretKey_applepay)
              : "",
            reddot_mode_googlepay:
              result?.reddot_mode_googlepay === 1 ? true : false,
            reddot_mid_googlepay: result?.reddot_mid_googlepay || "",
            reddot_secretKey_googlepay: result?.reddot_secretKey_googlepay
              ? decodeValue(result?.reddot_secretKey_googlepay)
              : "",
            reddot_mode_atome: result?.reddot_mode_atome === 1 ? true : false,
            reddot_mid_atome: result?.reddot_mid_atome || "",
            reddot_secretKey_atome: result?.reddot_secretKey_atome
              ? decodeValue(result?.reddot_secretKey_atome)
              : "",
            reddot_mode_paynow: result?.reddot_mode_paynow === 1 ? true : false,
            reddot_mid_paynow: result?.reddot_mid_paynow || "",
            reddot_secretKey_paynow: result?.reddot_secretKey_paynow
              ? decodeValue(result?.reddot_secretKey_paynow)
              : "",
            reddot_mode_grabpay:
              result?.reddot_mode_grabpay === 1 ? true : false,
            reddot_mid_grabpay: result?.reddot_mid_grabpay || "",
            reddot_secretKey_grabpay: result?.reddot_secretKey_grabpay
              ? decodeValue(result?.reddot_secretKey_grabpay)
              : "",
            company_payment_method: {
              label: result.company_payment_method,
              value: result.company_payment_method,
            },
            action: "edit",
          };
          this.setState({ clientdata: clientupdatedata, pageloading: false });
        } else {
          showAlert("Error", "Invalid Company", "error");
        }
      });
    }
  }

  companycategoryList() {
    var urlShringTxt = apiUrl + "company/companycategory/categoryselectlist";

    axios.get(urlShringTxt, masterheaderconfig).then((res) => {
      var categoryList = [];
      if (res.data.status === "ok") {
        res.data.result.map((item) => {
          categoryList.push({
            label: item.cate_name,
            value: item.cate_id,
          });
          return "";
        });
      }
      this.setState({ categoryList: categoryList });
    });
  }

  loadCountyList() {
    var urlShringTxt = apiUrl + "company/settings/country_list";

    axios.get(urlShringTxt, masterheaderconfig).then((res) => {
      var countryList = [];
      if (res.data.status === "success") {
        res.data.result.map((item) => {
          countryList.push({
            label: item.country_name,
            value: item.country_id,
          });
          return "";
        });
      }
      this.setState({ countryList: countryList });
    });
  }
  loadCurrency() {
    var urlShringTxt = apiUrl + "company/settings/currency_list";

    axios.get(urlShringTxt, masterheaderconfig).then((res) => {
      var currencyList = [];
      if (res.data.status === "success") {
        res.data.result.map((item) => {
          currencyList.push({
            label: item.currency_name,
            value: item.currency_name,
          });
          return "";
        });
      }
      this.setState({ currencyList: currencyList });
    });
  }
  loadLanguage() {
    var urlShringTxt = apiUrl + "company/settings/language_list";

    axios.get(urlShringTxt, masterheaderconfig).then((res) => {
      var languageList = [];
      if (res.data.status === "success") {
        res.data.result.map((item) => {
          languageList.push({
            label: item.language_code,
            value: item.language_code,
          });
          return "";
        });
      }
      this.setState({ languageList: languageList });
    });
  }

  sateValChange = (field, value) => {
    this.setState({ btndisabled: value });
  };

  handleChange(checked, name) {
    this.setState({ checked });
  }

  /* signin - start*/
  fieldChange = (field, value) => {
    this.setState(
      update(this.state, { clientdata: { [field]: { $set: value } } })
    );
  };

  handleSubmit = () => {
    showLoader("submit_frm", "class");
    var client_data = this.state.clientdata;

    let cate_idtxt = "";
    let categorylistvl = client_data.category_list;
    if (
      categorylistvl !== undefined &&
      Object.keys(categorylistvl).length > 0
    ) {
      categorylistvl.map((valuetxt, index) => {
        cate_idtxt =
          index === 0 ? valuetxt.value : cate_idtxt + "~" + valuetxt.value;
        return "";
      });
    }
    client_data["category_list"] = cate_idtxt;
    client_data["company_country"] = client_data.company_country?.value || "";

    client_data["company_status"] = client_data.company_status?.value || "I";
    client_data["company_merchants_type"] =
      client_data.company_merchants_type?.value || "1";
    client_data["company_currency"] = client_data.company_currency?.value || "";
    client_data["company_date_format"] =
      client_data.company_date_format?.value || "";
    client_data["company_time_format"] =
      client_data.company_time_format?.value || "";
    client_data["company_language"] = client_data.company_language?.value || "";
    client_data["company_payment_method"] =
      client_data.company_payment_method?.value || "";
    client_data["loginID"] = userID();
    var post_url = "company/companycontroller/add";
    if (client_data.action === "edit" && this.state.editID !== "") {
      client_data["edit_id"] = this.state.editID;
      post_url = "company/companycontroller/edit";
    }
    this.props.getFormPost(client_data, post_url, "master");
  };

  render() {
    return (
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Header {...this.props} currentPage={"client"} />
          <div className="layout-page">
            <Topmenu />
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <div className="row mb-3">
                  <div className="col-lg-10 col-md-6">
                    <h4 className="fw-bold">
                      {this.state.editID !== "" ? "Update" : "Add New"} Client
                    </h4>
                  </div>
                  <div className="col-lg-2 col-md-6 text-end">
                    <Link to={"/masterpanel/client"}>
                      <button
                        type="button"
                        className="btn btn-outline-primary waves-effect"
                      >
                        Back
                      </button>
                    </Link>
                  </div>
                </div>
                <Form
                  {...this.props}
                  fields={this.state.clientdata}
                  btndisabled={this.state.btndisabled}
                  onChange={this.fieldChange}
                  sateValChange={this.sateValChange}
                  onValid={this.handleSubmit}
                  error_msg={this.state.error_msg}
                  categoryList={this.state.categoryList}
                  countryList={this.state.countryList}
                  currencyList={this.state.currencyList}
                  languageList={this.state.languageList}
                  onInvalid={() => {
                    setTimeout(function () {
                      if ($("#modulefrm .is-invalid").length > 0) {
                        $("html, body").animate(
                          {
                            scrollTop:
                              $(document)
                                .find("#modulefrm .is-invalid:first")
                                .offset().top - 100,
                          },
                          500
                        );
                      }
                    }, 500);
                  }}
                />
              </div>

              <Footer />
            </div>
          </div>
        </div>

        <div className="layout-overlay layout-menu-toggle"></div>

        <div className="drag-target"></div>
        <PageLoader pageloading={this.state.pageloading} />
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    formpost: state.formpost,
    detaildata: state.detaildata,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFormPost: (formPayload, postUrl, authType) => {
      dispatch({ type: GET_FORMPOST, formPayload, postUrl, authType });
    },
    getDetailData: (datas) => {
      dispatch({ type: GET_DETAILDATA, datas });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Clientform);

const isEmpty = (value) => (value === "" ? "This field is required." : null);
const minLength = (password, length) =>
  password.length >= length || password === ""
    ? null
    : `Password must be at least ${length} characters.`;

const areSame = (password, rePassword) =>
  password === rePassword ? null : "Password do not match.";
const isEmail = (email) =>
  validator.isEmail(email) ? null : "This is not a valid email.";

function validationConfig(props) {
  const {
    company_name,
    company_username,
    company_password,
    company_cpassword,
    company_email_address,
    company_status,
    company_merchants_type,
  } = props.fields;
  if (props.fields.action === "add") {
    return {
      fields: [
        "company_name",
        "company_username",
        "company_password",
        "company_cpassword",
        "company_email_address",
        "company_status",
        "company_merchants_type",
      ],

      validations: {
        company_name: [[isEmpty, company_name]],
        company_username: [[isEmpty, company_username]],
        company_password: [
          [isEmpty, company_password],
          [minLength, company_password, 10],
          [passwordChar, company_password],
        ],
        company_cpassword: {
          rules: [
            [areSame, company_password, company_cpassword],
            [isEmpty, company_cpassword],
          ],
          fields: ["company_password", "company_cpassword"],
        },
        company_email_address: [
          [isEmpty, company_email_address],
          [isEmail, company_email_address],
        ],
        company_status: [[isSingleSelect, company_status]],
        company_merchants_type: [[isSingleSelect, company_merchants_type]],
      },
    };
  } else {
    return {
      fields: [
        "company_name",
        "company_username",
        "company_password",
        "company_cpassword",
        "company_email_address",
        "company_status",
        "company_merchants_type",
      ],

      validations: {
        company_name: [[isEmpty, company_name]],
        company_username: [[isEmpty, company_username]],
        company_password: [
          [minLength, company_password, 10],
          [passwordChar, company_password],
        ],
        company_cpassword: {
          rules: [[areSame, company_password, company_cpassword]],
          fields: ["company_password", "company_cpassword"],
        },
        company_email_address: [
          [isEmpty, company_email_address],
          [isEmail, company_email_address],
        ],
        company_status: [[isSingleSelect, company_status]],
        company_merchants_type: [[isSingleSelect, company_merchants_type]],
      },
    };
  }
}

class Form extends Component {
  handleChange(name, value) {
    this.props.onChange(name, value);
  }
  handleSelectChange(name, value) {
    this.props.onChange(name, value);
  }
  handleChangeRadio(fields, name, value) {
    var selectedAvail = value.target.value;
    var company_availability_name = fields.company_availability_name;
    if (value.target.checked === true) {
      company_availability_name.push(value.target.value);
    } else {
      const indexNew = company_availability_name.indexOf(selectedAvail);
      company_availability_name.splice(indexNew, 1);
    }
    this.props.onChange(name, company_availability_name);
  }

  async uplaodFiles(imageType) {
    this.props.sateValChange("btndisabled", true);
    showLoader("ImageDetails");
    showLoader("ImageContainer");
    var imagefile = document.querySelector("#" + imageType);
    const file = imagefile.files[0];
    var fileDetail = await uploadFile(file, "company", "master");
    if (fileDetail.status === "ok") {
      this.props.onChange(imageType, fileDetail.url);
    }
    hideLoader("ImageContainer");
    hideLoader("ImageDetails");
    $("#" + imageType).val("");
    this.props.sateValChange("btndisabled", false);
  }
  async removeImage(fileNamme, imageType, e) {
    e.preventDefault();
    showLoader("ImageDetails", "class");
    var fileDetail = await removeFile(fileNamme, "master");
    if (fileDetail.status === "ok") {
      hideLoader("ImageDetails", "class");
      this.props.onChange(imageType, "");
    }
  }

  /*  async uplaodcompanyImage() {
    var imagefile = document.querySelector("#company_logo");
    const file = imagefile.files[0];
    const params = {
      Bucket: bucketName,
      Key: `media/${foldername}/outlet/${file.name}`,
      Body: file,
      ACL: "public-read",
    };
    const { Location } = await s3.upload(params).promise();
    this.props.onChange("company_logo", Location);
    $("#company_logo").val("");
  }

  async removeImage(fileNamme, ImageType, event) {
    event.preventDefault();
    var imagePath = "";
    if (ImageType === "company_logo") {
      imagePath = "outlet";
    }
    var fileNammeSplit = fileNamme.split("/");
    console.log(fileNammeSplit, "fileNammeSplit");
    var params = {
      Bucket: bucketName,
      Key: `media/${foldername}/${imagePath}/${
        fileNammeSplit[fileNammeSplit.length - 1]
      }`,
    };
    await s3.deleteObject(params).promise();
    this.props.onChange(ImageType, "");
  } */

  render() {
    const { fields, onChange, onValid, onInvalid, $field, $validation } =
      this.props;
    let errMsgName,
      errMsgUsername,
      errMsgPassword,
      errMsgConfirmPassword,
      errMssEmail,
      errMsgStatus,
      errMsgMerType = "";
    if ($validation.company_name.error.reason !== undefined) {
      errMsgName = $validation.company_name.show && (
        <span className="error">{$validation.company_name.error.reason}</span>
      );
    }

    if ($validation.company_username.error.reason !== undefined) {
      errMsgUsername = $validation.company_username.show && (
        <span className="error">
          {$validation.company_username.error.reason}
        </span>
      );
    }
    if ($validation.company_password.error.reason !== undefined) {
      errMsgPassword = $validation.company_password.show && (
        <span className="error">
          {$validation.company_password.error.reason}
        </span>
      );
    }
    if ($validation.company_cpassword.error.reason !== undefined) {
      errMsgConfirmPassword = $validation.company_cpassword.show && (
        <span className="error">
          {$validation.company_cpassword.error.reason}
        </span>
      );
    }
    if ($validation.company_email_address.error.reason !== undefined) {
      errMssEmail = $validation.company_email_address.show && (
        <span className="error">
          {$validation.company_email_address.error.reason}
        </span>
      );
    }
    /* if ($validation.company_amount.error.reason !== undefined) {
      errMssAmount = $validation.company_amount.show && (
        <span className="error">{$validation.company_amount.error.reason}</span>
      );
    } */

    if ($validation.company_status.error.reason !== undefined) {
      errMsgStatus = $validation.company_status.show && (
        <span className="error">{$validation.company_status.error.reason}</span>
      );
    }
    if ($validation.company_merchants_type.error.reason !== undefined) {
      errMsgMerType = $validation.company_merchants_type.show && (
        <span className="error">
          {$validation.compancompany_merchants_typey_status.error.reason}
        </span>
      );
    }
    const reddotList = [
      { label: "Mastetr Visa Card With 3DS", key: "mv3ds" },
      { label: "Amex Card With 3DS", key: "amex3ds" },
      { label: "Mastetr Visa Card Without 3DS", key: "mvwithout3ds" },
      { label: "Amex Card Without 3DS", key: "amexwithout3ds" },
      { label: "ApplePay", key: "applepay" },
      { label: "GooglePay", key: "googlepay" },
      { label: "Atome", key: "atome" },
      { label: "PayNow", key: "paynow" },
      { label: "GrabPay", key: "grabpay" },
    ];

    return (
      <form className="card-body fv-plugins-bootstrap5" id="modulefrm">
        <div
          className="accordion mt-3 accordion-header-primary"
          id="accordionStyle1"
        >
          <div className="accordion-item active">
            <h2 className="accordion-header">
              <button
                type="button"
                className="accordion-button"
                data-bs-toggle="collapse"
                data-bs-target="#accordionStyle1-3"
                aria-expanded="true"
              >
                Client Info
              </button>
            </h2>
            <div
              id="accordionStyle1-3"
              className="accordion-collapse collapse show"
              data-bs-parent="#accordionStyle1"
            >
              <div className="accordion-body">
                <div className="row g-3">
                  <div className="col-md-6">
                    <div className="form-floating form-floating-outline mb-4">
                      <input
                        type="text"
                        className={
                          errMsgName !== "" &&
                          errMsgName !== false &&
                          errMsgName !== undefined
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        name="company_name"
                        value={fields.company_name}
                        {...$field("company_name", (e) =>
                          onChange("company_name", e.target.value)
                        )}
                      />
                      <label htmlFor="company_name">
                        Client Name <span className="error">*</span>
                      </label>
                      {errMsgName}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-floating form-floating-outline mb-4">
                      <input
                        type="text"
                        className={
                          errMsgUsername !== "" &&
                          errMsgUsername !== false &&
                          errMsgUsername !== undefined
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        name="company_username"
                        value={fields.company_username}
                        {...$field("company_username", (e) =>
                          onChange("company_username", e.target.value)
                        )}
                      />
                      <label htmlFor="company_username">
                        Username <span className="error">*</span>
                      </label>
                      {errMsgUsername}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-4 form-password-toggle">
                      <div className="input-group input-group-merge">
                        <div className="form-floating form-floating-outline">
                          <input
                            type="password"
                            id="company_password"
                            className={
                              errMsgPassword !== "" &&
                              errMsgPassword !== false &&
                              errMsgPassword !== undefined
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            value={fields.company_password}
                            {...$field("company_password", (e) =>
                              onChange("company_password", e.target.value)
                            )}
                          />
                          <label htmlFor="company_password">Password</label>
                          {errMsgPassword}
                        </div>
                        <span
                          className="input-group-text rounded-end cursor-pointer"
                          id="basic-default-password4"
                          style={{ height: "49px" }}
                        >
                          <i className="mdi mdi-eye-off-outline"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-4 form-password-toggle">
                      <div className="input-group input-group-merge">
                        <div className="form-floating form-floating-outline">
                          <input
                            type="password"
                            id="company_cpassword"
                            className={
                              errMsgConfirmPassword !== "" &&
                              errMsgConfirmPassword !== false &&
                              errMsgConfirmPassword !== undefined
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            value={fields.company_cpassword}
                            {...$field("company_cpassword", (e) =>
                              onChange("company_cpassword", e.target.value)
                            )}
                          />
                          <label htmlFor="company_cpassword">
                            Confirm Password
                          </label>
                          {errMsgConfirmPassword}
                        </div>
                        <span
                          className="input-group-text rounded-end cursor-pointer"
                          id="basic-default-password3"
                          style={{ height: "49px" }}
                        >
                          <i className="mdi mdi-eye-off-outline"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-floating form-floating-outline mb-4">
                      <input
                        type="text"
                        className={
                          errMssEmail !== "" &&
                          errMssEmail !== false &&
                          errMssEmail !== undefined
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        name="company_email_address"
                        id="company_email_address"
                        value={fields.company_email_address}
                        {...$field("company_email_address", (e) =>
                          onChange("company_email_address", e.target.value)
                        )}
                      />
                      <label htmlFor="company_email_address">
                        Email ID <span className="error">*</span>
                      </label>
                      {errMssEmail}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-floating form-floating-outline custm-select-box">
                      <Select
                        value={fields.category_list}
                        onChange={this.handleSelectChange.bind(
                          this,
                          "category_list"
                        )}
                        placeholder="Select Category"
                        isMulti={true}
                        options={this.props.categoryList}
                      />
                      <label className="select-box-label">Categories</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-floating form-floating-outline custm-select-box">
                      <Select
                        value={fields.company_country}
                        onChange={this.handleSelectChange.bind(
                          this,
                          "company_country"
                        )}
                        placeholder="Select Country"
                        options={this.props.countryList}
                      />
                      <label className="select-box-label">Country</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-floating form-floating-outline custm-select-box">
                      <Select
                        value={fields.company_currency}
                        onChange={this.handleSelectChange.bind(
                          this,
                          "company_currency"
                        )}
                        placeholder="Select Currency"
                        options={this.props.currencyList}
                      />
                      <label className="select-box-label">Currency</label>
                    </div>
                  </div>
                  <div className="col-md-6 mt-4">
                    <div className="form-floating form-floating-outline custm-select-box">
                      <Select
                        value={fields.company_language}
                        onChange={this.handleSelectChange.bind(
                          this,
                          "company_language"
                        )}
                        placeholder="Select Language"
                        options={this.props.languageList}
                      />
                      <label className="select-box-label">Language</label>
                    </div>
                  </div>
                  <div className="col-md-6  mt-4">
                    <div className="form-floating form-floating-outline custm-select-box">
                      <Select
                        value={fields.company_date_format}
                        onChange={this.handleSelectChange.bind(
                          this,
                          "company_date_format"
                        )}
                        placeholder="Select Date Format"
                        options={[
                          {
                            value: "LLLL d, yyyy",
                            label: format(todayDate, "LLLL d, yyyy"),
                          },
                          {
                            value: "yyyy-MM-dd",
                            label: format(todayDate, "yyyy-MM-dd"),
                          },
                          {
                            value: "MM/dd/yyyy",
                            label: format(todayDate, "MM/dd/yyyy"),
                          },
                          {
                            value: "MM/dd/yy",
                            label: format(todayDate, "MM/dd/yy"),
                          },
                          {
                            value: "dd/MM/yyyy",
                            label: format(todayDate, "dd/MM/yyyy"),
                          },
                          {
                            value: "dd-MM-yyyy",
                            label: format(todayDate, "dd-MM-yyyy"),
                          },
                        ]}
                      />
                      <label className="select-box-label">Date Format</label>
                    </div>
                  </div>
                  <div className="col-md-6 mt-4">
                    <div className="form-floating form-floating-outline custm-select-box ">
                      <Select
                        value={fields.company_time_format}
                        onChange={this.handleSelectChange.bind(
                          this,
                          "company_time_format"
                        )}
                        placeholder="Select Time Format"
                        options={[
                          {
                            value: "hh:mm a",
                            label: format(todayDate, "hh:mm a"),
                          },
                          {
                            value: "HH:mm",
                            label: format(todayDate, "HH:mm"),
                          },
                        ]}
                      />
                      <label className="select-box-label">Time Format</label>
                    </div>
                  </div>
                  <div className="col-md-6 mt-4">
                    <div className="form-floating form-floating-outline mb-4">
                      <input
                        type="text"
                        className={
                          errMssEmail !== "" &&
                          errMssEmail !== false &&
                          errMssEmail !== undefined
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        name="company_amount"
                        id="company_amount"
                        value={fields.company_amount}
                        {...$field("company_amount", (e) =>
                          onChange("company_amount", e.target.value)
                        )}
                      />
                      <label htmlFor="company_amount">
                        Subscription Amount
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-floating form-floating-outline custm-select-box">
                      <Select
                        value={fields.company_payment_method}
                        onChange={this.handleSelectChange.bind(
                          this,
                          "company_payment_method"
                        )}
                        placeholder="Select Payment Method"
                        options={[
                          { value: "online", label: "Online" },
                          { value: "offline", label: "Offline" },
                        ]}
                      />
                      <label className="select-box-label">Payment Method</label>
                    </div>
                  </div>

                  <div
                    className={
                      errMsgMerType !== "" &&
                      errMsgMerType !== false &&
                      errMsgMerType !== undefined
                        ? "col-md-6 error-select error"
                        : "col-md-6"
                    }
                  >
                    <div className="form-floating form-floating-outline custm-select-box">
                      <Select
                        value={fields.company_merchants_type}
                        onChange={this.handleSelectChange.bind(
                          this,
                          "company_merchants_type"
                        )}
                        placeholder="Select Merchants Type"
                        options={[
                          { value: "2", label: "Voucher Only" },
                          { value: "1", label: "Complete " },
                        ]}
                      />
                      <label className="select-box-label">
                        Merchants Type<span className="error">*</span>
                      </label>
                      {errMsgMerType}
                    </div>
                  </div>
                  <div
                    className={
                      errMsgStatus !== "" &&
                      errMsgStatus !== false &&
                      errMsgStatus !== undefined
                        ? "col-md-6 error-select error"
                        : "col-md-6"
                    }
                  >
                    <div className="form-floating form-floating-outline custm-select-box mt-4">
                      <Select
                        value={fields.company_status}
                        onChange={this.handleSelectChange.bind(
                          this,
                          "company_status"
                        )}
                        placeholder="Select Status"
                        options={[
                          { value: "A", label: "Active" },
                          { value: "I", label: "Inactive" },
                        ]}
                      />
                      <label className="select-box-label">
                        Status<span className="error">*</span>
                      </label>
                      {errMsgStatus}
                    </div>
                  </div>
                  <div className="col-md-6"></div>

                  <div className="col-md-6">
                    <div className="form-floating form-floating-outline mb-4">
                      <div className="mb-3" id="ImageContainer">
                        <label htmlFor="formFile" className="form-label">
                          Company Image
                        </label>
                        <input
                          className="form-control"
                          type="file"
                          id="company_logo"
                          onChange={(event) => {
                            this.uplaodFiles("company_logo", event);
                            return false;
                          }}
                        />
                      </div>
                    </div>
                    {fields.company_logo !== "" && (
                      <div className="dz-preview dz-processing dz-image-preview dz-success dz-complete">
                        <div className="dz-details" id="ImageDetails">
                          <div className="dz-thumbnail">
                            <img alt="" src={fields.company_logo} />
                          </div>
                        </div>
                        <a
                          className="dz-remove"
                          href="/"
                          onClick={this.removeImage.bind(
                            this,
                            fields.company_logo,
                            "company_logo"
                          )}
                        >
                          Remove file
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="accordion-item ">
            <h2 className="accordion-header">
              <button
                type="button"
                className="accordion-button collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#accordionStyle1-1"
                aria-expanded="false"
              >
                Basic Settings
              </button>
            </h2>

            <div
              id="accordionStyle1-1"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionStyle1"
            >
              <div className="accordion-body">
                <div className="row g-3">
                  <div className="col-md-3">
                    <div className="form-floating form-floating-outline mb-4">
                      <Switch
                        onChange={this.handleChange.bind(
                          this,
                          "enable_maintenance_mode"
                        )}
                        checked={fields.enable_maintenance_mode}
                        onColor="#666cff"
                        className="react-switch"
                        onHandleColor="#bdbfff"
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      />
                      <span>Enable Maintenance Mode</span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-floating form-floating-outline mb-4">
                      <Switch
                        onChange={this.handleChange.bind(
                          this,
                          "enable_subscription"
                        )}
                        checked={fields.enable_subscription}
                        onColor="#666cff"
                        className="react-switch"
                        onHandleColor="#bdbfff"
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      />
                      <span>Enable Subscription</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                type="button"
                className="accordion-button collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#accordionStyle1-2"
                aria-expanded="false"
              >
                Promotion Settings
              </button>
            </h2>
            <div
              id="accordionStyle1-2"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionStyle1"
            >
              <div className="accordion-body">
                <div className="row g-3">
                  <div className="col-md-3">
                    <div className="form-floating form-floating-outline mb-4">
                      <Switch
                        onChange={this.handleChange.bind(
                          this,
                          "enable_loyalty"
                        )}
                        checked={fields.enable_loyalty}
                        onColor="#666cff"
                        className="react-switch"
                        onHandleColor="#bdbfff"
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      />
                      <span>Enable Loyalty</span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-floating form-floating-outline mb-4">
                      <Switch
                        onChange={this.handleChange.bind(
                          this,
                          "enable_promocode"
                        )}
                        checked={fields.enable_promocode}
                        onColor="#666cff"
                        className="react-switch"
                        onHandleColor="#bdbfff"
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      />
                      <span>Enable Promocode</span>
                    </div>
                  </div>
                  <div className="col-md-3" style={{ display: "none" }}>
                    <div className="form-floating form-floating-outline mb-4">
                      <Switch
                        onChange={this.handleChange.bind(
                          this,
                          "enable_new_signup_promocode"
                        )}
                        checked={fields.enable_new_signup_promocode}
                        onColor="#666cff"
                        className="react-switch"
                        onHandleColor="#bdbfff"
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      />
                      <span>Enable New Signup Promocode</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="accordion-item " style={{ display: "none" }}>
            <h2 className="accordion-header">
              <button
                type="button"
                className="accordion-button collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#accordiontime"
                aria-expanded="false"
              >
                Time Settings
              </button>
            </h2>

            <div
              id="accordiontime"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionStyle1"
            >
              <div className="accordion-body">
                <div className="row g-3">
                  <div className="col-md-3">
                    <div className="form-floating form-floating-outline mb-4">
                      <Switch
                        onChange={this.handleChange.bind(
                          this,
                          "enable_holiday"
                        )}
                        checked={fields.enable_holiday}
                        onColor="#666cff"
                        className="react-switch"
                        onHandleColor="#bdbfff"
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      />
                      <span>Enable Holiday</span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-floating form-floating-outline mb-4">
                      <Switch
                        onChange={this.handleChange.bind(
                          this,
                          "enable_order_count"
                        )}
                        checked={fields.enable_order_count}
                        onColor="#666cff"
                        className="react-switch"
                        onHandleColor="#bdbfff"
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      />
                      <span>Enable Order Count</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="accordion-item ">
            <h2 className="accordion-header">
              <button
                type="button"
                className="accordion-button collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#accordiontax"
                aria-expanded="false"
              >
                Tax Settings
              </button>
            </h2>

            <div
              id="accordiontax"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionStyle1"
            >
              <div className="accordion-body">
                <div className="row g-3">
                  <div className="col-md-3">
                    <div className="form-floating form-floating-outline mb-4">
                      <Switch
                        onChange={this.handleChange.bind(this, "enable_tax")}
                        checked={fields.enable_tax}
                        onColor="#666cff"
                        className="react-switch"
                        onHandleColor="#bdbfff"
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      />
                      <span>Enable Tax</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="accordion-item " style={{ display: "none" }}>
            <h2 className="accordion-header">
              <button
                type="button"
                className="accordion-button collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#accordionproduct"
                aria-expanded="false"
              >
                Product Settings
              </button>
            </h2>

            <div
              id="accordionproduct"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionStyle1"
            >
              <div className="accordion-body">
                <div className="row g-3">
                  <div className="col-md-3">
                    <div className="form-floating form-floating-outline mb-4">
                      <Switch
                        onChange={this.handleChange.bind(
                          this,
                          "enable_highlight_product"
                        )}
                        checked={fields.enable_highlight_product}
                        onColor="#666cff"
                        className="react-switch"
                        onHandleColor="#bdbfff"
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      />
                      <span>Enable Highlight Product</span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-floating form-floating-outline mb-4">
                      <Switch
                        onChange={this.handleChange.bind(
                          this,
                          "enable_product_time_availability"
                        )}
                        checked={fields.enable_product_time_availability}
                        onColor="#666cff"
                        className="react-switch"
                        onHandleColor="#bdbfff"
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      />
                      <span>Enable Product Time Availability</span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-floating form-floating-outline mb-4">
                      <Switch
                        onChange={this.handleChange.bind(
                          this,
                          "enable_productspecial_days"
                        )}
                        checked={fields.enable_productspecial_days}
                        onColor="#666cff"
                        className="react-switch"
                        onHandleColor="#bdbfff"
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      />
                      <span>Enable Product Special Days</span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-floating form-floating-outline mb-4">
                      <Switch
                        onChange={this.handleChange.bind(
                          this,
                          "enable_product_rating"
                        )}
                        checked={fields.enable_product_rating}
                        onColor="#666cff"
                        className="react-switch"
                        onHandleColor="#bdbfff"
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      />
                      <span>Enable Product Rating</span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-floating form-floating-outline mb-4">
                      <Switch
                        onChange={this.handleChange.bind(this, "enable_stock")}
                        checked={fields.enable_stock}
                        onColor="#666cff"
                        className="react-switch"
                        onHandleColor="#bdbfff"
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      />
                      <span>Enable Stock</span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-floating form-floating-outline mb-4">
                      <Switch
                        onChange={this.handleChange.bind(
                          this,
                          "enable_stock_auto_update"
                        )}
                        checked={fields.enable_stock_auto_update}
                        onColor="#666cff"
                        className="react-switch"
                        onHandleColor="#bdbfff"
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      />
                      <span>Enable Stock Auto Update</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="accordion-item ">
            <h2 className="accordion-header">
              <button
                type="button"
                className="accordion-button collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#accordionsms"
                aria-expanded="false"
              >
                SMS Settings
              </button>
            </h2>

            <div
              id="accordionsms"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionStyle1"
            >
              <div className="accordion-body">
                <div className="row g-3">
                  <div className="col-md-6">
                    <div className="form-floating form-floating-outline mb-4">
                      <input
                        type="text"
                        className="form-control"
                        name="sms_count"
                        id="sms_count"
                        value={fields.sms_count}
                        {...$field("sms_count", (e) =>
                          onChange("sms_count", e.target.value)
                        )}
                      />
                      <label htmlFor="sms_count">SMS Count / Period</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="accordion-item " style={{ display: "none" }}>
            <h2 className="accordion-header">
              <button
                type="button"
                className="accordion-button collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#accordioncustomer"
                aria-expanded="false"
              >
                Customer Settings
              </button>
            </h2>

            <div
              id="accordioncustomer"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionStyle1"
            >
              <div className="accordion-body">
                <div className="row g-3">
                  <div className="col-md-3">
                    <div className="form-floating form-floating-outline mb-4">
                      <Switch
                        onChange={this.handleChange.bind(
                          this,
                          "enable_corporate_customer"
                        )}
                        checked={fields.enable_corporate_customer}
                        onColor="#666cff"
                        className="react-switch"
                        onHandleColor="#bdbfff"
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      />
                      <span>Enable Corporate Customer </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="accordion-item ">
            <h2 className="accordion-header">
              <button
                type="button"
                className="accordion-button collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#accordionpayment"
                aria-expanded="false"
              >
                Membership Settings
              </button>
            </h2>

            <div
              id="accordionpayment"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionStyle1"
            >
              <div className="accordion-body">
                <div className="row g-3">
                  <div className="col-md-3">
                    <div className="form-floating form-floating-outline mb-4">
                      <Switch
                        onChange={this.handleChange.bind(
                          this,
                          "enable_membership"
                        )}
                        checked={fields.enable_membership}
                        onColor="#666cff"
                        className="react-switch"
                        onHandleColor="#bdbfff"
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      />
                      <span>Enable Membership</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="accordion-item ">
            <h2 className="accordion-header">
              <button
                type="button"
                className="accordion-button collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#accordionmembership"
                aria-expanded="false"
              >
                Payment Settings
              </button>
            </h2>
            <div
              id="accordionmembership"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionStyle1"
            >
              <div className="accordion-body">
                <div className="row g-3">
                  <div className="col-md-12">
                    <div className="form-floating form-floating-outline mb-4">
                      <Switch
                        onChange={this.handleChange.bind(this, "enable_strip")}
                        checked={fields.enable_strip}
                        onColor="#666cff"
                        className="react-switch"
                        onHandleColor="#bdbfff"
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      />
                      <span>Enable Strip</span>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-floating form-floating-outline mb-4">
                      <Switch
                        onChange={this.handleChange.bind(
                          this,
                          "enable_manual_payment"
                        )}
                        checked={fields.enable_manual_payment}
                        onColor="#666cff"
                        className="react-switch"
                        onHandleColor="#bdbfff"
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      />
                      <span>Enable Manual Payment</span>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-floating form-floating-outline mb-4">
                      <Switch
                        onChange={this.handleChange.bind(
                          this,
                          "enable_mswipe_payment"
                        )}
                        checked={fields.enable_mswipe_payment}
                        onColor="#666cff"
                        className="react-switch"
                        onHandleColor="#bdbfff"
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      />
                      <span>Enable MSwipe</span>
                    </div>
                  </div>
                  {fields.enable_mswipe_payment === true && (
                    <div className="row">
                      <div className="col-md-2">
                        <div className="form-floating form-floating-outline mb-4">
                          <Switch
                            onChange={this.handleChange.bind(
                              this,
                              "mswipe_mode"
                            )}
                            checked={fields.mswipe_mode}
                            onColor="#666cff"
                            className="react-switch"
                            onHandleColor="#bdbfff"
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                          />
                          <span>Mode</span>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-floating form-floating-outline mb-4">
                          <input
                            type="text"
                            className="form-control"
                            name="mswipe_refid"
                            id="mswipe_refid"
                            value={fields.mswipe_refid}
                            {...$field("mswipe_refid", (e) =>
                              onChange("mswipe_refid", e.target.value)
                            )}
                          />
                          <label htmlFor="mswipe_refid">Ref ID</label>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-floating form-floating-outline mb-4">
                          <input
                            type="text"
                            className="form-control"
                            name="mswipe_custCode"
                            id="mswipe_custCode"
                            value={fields.mswipe_custCode}
                            {...$field("mswipe_custCode", (e) =>
                              onChange("mswipe_custCode", e.target.value)
                            )}
                          />
                          <label htmlFor="mswipe_custCode">Customer Code</label>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-floating form-floating-outline mb-4">
                          <input
                            type="text"
                            className="form-control"
                            name="mswipe_user_name"
                            id="mswipe_user_name"
                            value={fields.mswipe_user_name}
                            {...$field("mswipe_user_name", (e) =>
                              onChange("mswipe_user_name", e.target.value)
                            )}
                          />
                          <label htmlFor="mswipe_user_name">Username</label>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-floating form-floating-outline mb-4">
                          <input
                            type="password"
                            className="form-control"
                            name="mswipe_user_pwd"
                            id="mswipe_user_pwd"
                            value={fields.mswipe_user_pwd}
                            {...$field("mswipe_user_pwd", (e) =>
                              onChange("mswipe_user_pwd", e.target.value)
                            )}
                          />
                          <label htmlFor="mswipe_user_pwd">Password</label>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="col-md-12">
                    <div className="form-floating form-floating-outline mb-4">
                      <Switch
                        onChange={this.handleChange.bind(
                          this,
                          "enable_reddot_payment"
                        )}
                        checked={fields.enable_reddot_payment}
                        onColor="#666cff"
                        className="react-switch"
                        onHandleColor="#bdbfff"
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      />
                      <span>Enable Reddot</span>
                    </div>
                  </div>
                  {fields.enable_reddot_payment === true && (
                    <div>
                      {reddotList.length > 0 &&
                        reddotList.map((item, index) => {
                          return (
                            <div key={index}>
                              <h4>{item.label}</h4>
                              <div className="row">
                                <div className="col-md-2">
                                  <div className="form-floating form-floating-outline mb-4">
                                    <Switch
                                      onChange={this.handleChange.bind(
                                        this,
                                        `reddot_mode_${item.key}`
                                      )}
                                      checked={
                                        fields[`reddot_mode_${item.key}`]
                                      }
                                      onColor="#666cff"
                                      className="react-switch"
                                      onHandleColor="#bdbfff"
                                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                    />
                                    <span>
                                      {fields[`reddot_mode_${item.key}`] ===
                                      true
                                        ? "Live"
                                        : "Sandbox"}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-floating form-floating-outline mb-4">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name={`reddot_mid_${item.key}`}
                                      id={`reddot_mid_${item.key}`}
                                      value={fields[`reddot_mid_${item.key}`]}
                                      {...$field(
                                        `reddot_mid_${item.key}`,
                                        (e) =>
                                          onChange(
                                            `reddot_mid_${item.key}`,
                                            e.target.value
                                          )
                                      )}
                                    />
                                    <label htmlFor={`reddot_mid_${item.key}`}>
                                      MID
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-5">
                                  <div className="form-floating form-floating-outline mb-4">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name={`reddot_secretKey_${item.key}`}
                                      id={`reddot_secretKey_${item.key}`}
                                      value={
                                        fields[`reddot_secretKey_${item.key}`]
                                      }
                                      {...$field(
                                        `reddot_secretKey_${item.key}`,
                                        (e) =>
                                          onChange(
                                            `reddot_secretKey_${item.key}`,
                                            e.target.value
                                          )
                                      )}
                                    />
                                    <label
                                      htmlFor={`reddot_secretKey_${item.key}`}
                                    >
                                      Secret Key
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pt-4 text-end">
          <button
            type="button"
            disabled={this.props.btndisabled}
            className="btn btn-primary me-sm-3 me-1 waves-effect waves-light submit_frm"
            onClick={(e) => {
              e.preventDefault();
              if (!this.props.btndisabled) {
                this.props.$submit(onValid, onInvalid);
              }
            }}
          >
            Submit
          </button>
          <Link to={"/masterpanel/client"}>
            <button
              type="reset"
              className="btn btn-label-secondary waves-effect"
            >
              Cancel
            </button>
          </Link>
        </div>
      </form>
    );
  }
}
Form = validated(validationConfig)(Form);
